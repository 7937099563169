import { useFieldArray, useFormContext } from 'react-hook-form';

import { Alert, Box, Inputs, Paragraph, Tooltip } from '@hl-portals/ui';

import { formatCurrency, normalizeCurrency } from '@hl-portals/helpers';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { LIENS, LIENS_OPTIONS, Lien, Liens } from '../../../metadata/constants';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

const RemoveIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C3.58065 0 0 3.58065 0 8C0 12.4194 3.58065 16 8 16C12.4194 16 16 12.4194 16 8C16 3.58065 12.4194 0 8 0Z"
      fill="#DBDFE6"
    />
    <path
      d="M3.35449 8.90314C3.35449 9.11605 3.52869 9.29024 3.74159 9.29024H12.2577C12.4706 9.29024 12.6448 9.11605 12.6448 8.90314V7.09669C12.6448 6.88379 12.4706 6.70959 12.2577 6.70959H3.74159C3.52869 6.70959 3.35449 6.88379 3.35449 7.09669V8.90314Z"
      fill="white"
    />
  </svg>
);

type Field = Lien & { id: string };

export const LiensBalances = () => {
  const { watch, setValue } = useFormContext();
  const { onUpdateSnapshot } = useLeadSubmissionContext();

  const { fields, append, remove, update } = useFieldArray({
    name: 'secondary_loans',
  });

  const liens = (watch('secondary_loans') as Liens) || [];
  const homeValue = watch('estimated_home_value');

  const total = liens.reduce((count, lien) => {
    return count + normalizeCurrency(lien.balance);
  }, 0);

  const showError = total > normalizeCurrency(homeValue);
  const showAdd = liens.length < LIENS.length - 1;
  const isEnabled = !showError && liens.every((lb) => !!lb.type);

  const onClick = () => {
    setValue('liens_total', total);
    onUpdateSnapshot();
  };

  return (
    <>
      <Content>
        <Container title="Please specify the balances">
          <Paragraph mb="48px" color="#55585E">
            If you are unsure of a balance enter 0.
          </Paragraph>

          <Box mb="20px" flex="1" flexDirection="column" gap="16px">
            {fields.map((field: Field, i) => {
              return (
                <Box key={field.id} gap="16px">
                  {field.label ? (
                    <Paragraph flex="1">{field.label}</Paragraph>
                  ) : (
                    <Inputs.Select
                      name={`secondary_loans.${i}.type`}
                      containerProps={{ flex: '1' }}
                      options={LIENS_OPTIONS.map((o) => ({
                        text: o.label,
                        value: o.type,
                        disabled: liens.some((lien) => lien.type === o.type),
                      }))}
                    />
                  )}
                  <Box maxWidth="192px" alignItems="center" gap="4px">
                    <Inputs.Currency
                      name={`secondary_loans.${i}.balance`}
                      onClear={() =>
                        update(i, {
                          type: field.type,
                          label: field.label,
                          balance: '0',
                        })
                      }
                    />
                    <Tooltip message="Remove">
                      <Box onClick={() => remove(i)} cursor="pointer">
                        <RemoveIcon />
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              );
            })}

            {showAdd && (
              <Paragraph
                alignSelf="flex-end"
                variant="text-small"
                color="#1192E5"
                cursor="pointer"
                onClick={() =>
                  append({ type: '', label: '', balance: '0' } as Lien)
                }
              >
                Add lien
              </Paragraph>
            )}

            <Box width="100%" height="1px" bgcolor="#DBDFE6" />

            <Box flexDirection="column" gap="8px">
              <Box
                p="16px 8px"
                justifyContent="space-between"
                bgcolor={showError ? '#FFF4F4' : '#F5F6F9'}
                borderRadius="8px"
              >
                <Paragraph color={showError ? '#CA0D02' : '#72757D'}>
                  Total balance
                </Paragraph>
                <Paragraph
                  fontWeight="600"
                  color={showError ? '#CA0D02' : '#273653'}
                >
                  {formatCurrency({ value: total })}
                </Paragraph>
              </Box>
            </Box>
            {showError && (
              <Paragraph color="#CA0D02">
                The total balance can’t exceed the home value of{' '}
                {formatCurrency({ value: homeValue })}. Please try again.
              </Paragraph>
            )}
          </Box>

          <Alert title="Why we ask this" mb={{ xs: '24px', md: '40px' }}>
            Entering accurate lien balances helps us to calculate how much
            equity can be unlocked from the departing residence.
          </Alert>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={onClick} disabled={!isEnabled} />
      </Footer>
    </>
  );
};
