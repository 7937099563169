import html2canvas from 'html2canvas';

import { useState } from 'react';

import { Box, Button, Icon, Paragraph, useBrandTheme } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { formatCurrency } from '@hl-portals/helpers';

import { leadSource } from '../constants';
import { useStepUtils } from '../hooks';
import { FloatingFAQButton } from '../layout/FloatingFAQButton';
import Footer from '../layout/Footer';
import Step from '../layout/Step';

type Assumption = {
  label: string;
  value: React.ReactNode;
  description?: string;
};

type EquityUnlockResultProps = {
  lowEndRange: string;
  highEndRange: string;
  assumptions: Assumption[];
};

export const AssumptionItem = ({ label, value, description }: Assumption) => (
  <Box justifyContent="space-between">
    <Box flexDirection="column">
      <Paragraph variant="text-small" mb={description && '4px'}>
        {label}
      </Paragraph>
      {description && (
        <Paragraph variant="text-xsmall" color="coolGray2">
          {description}
        </Paragraph>
      )}
    </Box>
    <Box fontSize="14px" textAlign="right">
      {value}
    </Box>
  </Box>
);

const EquityUnlockResult = ({
  lowEndRange,
  highEndRange,
  assumptions,
}: EquityUnlockResultProps) => {
  const brandTheme = useBrandTheme();

  return (
    <Box gap="16px" flexDirection="column">
      <Box flexDirection="column" width="100%">
        <Box
          flexDirection="column"
          bgcolor={brandTheme?.colors?.secondary || theme.colors.lightBlue}
          p={{ xs: '24px 32px', md: '40px 32px 24px' }}
          gap="8px"
          borderRadius="8px 8px 0px 0px"
          border={`1px solid ${theme.colors.blue300}`}
          position="relative"
        >
          <Paragraph
            variant="text-xsmall"
            fontWeight="600"
            letterSpacing="1px"
            textTransform="uppercase"
          >
            Equity Unlock Range:
          </Paragraph>
          <Paragraph variant="heading-2" fontSize={{ xs: '24px', md: '32px' }}>
            {lowEndRange} - {highEndRange}
          </Paragraph>
          {brandTheme.logoUrl && (
            <Box
              position="absolute"
              right="32px"
              height="100%"
              top="0px"
              alignItems="center"
              display={{ xs: 'none', lg: 'flex' }}
            >
              <Box as="img" src={brandTheme.logoUrl} width="130px" />
            </Box>
          )}
        </Box>
        <Box
          flexDirection="column"
          borderRadius="0px 0px 8px 8px"
          p="24px 24px 32px"
          border={`1px solid ${theme.colors.coolGray5}`}
          borderTop="0px"
          gap="16px"
          bgcolor="white"
        >
          <Paragraph variant="text-small" fontWeight="600">
            Assumptions:
          </Paragraph>
          {assumptions.map((assumption) => (
            <AssumptionItem {...assumption} key={assumption.label} />
          ))}
        </Box>
      </Box>
      <Paragraph variant="text-xsmall" color="coolGray2">
        This range is meant to be a guide/estimate of the equity unlock amount
        based off of your input and our system's initial review of your
        property. This is not a commitment to lend and is not a formal approval.
        Final numbers will be provided after further review and additional
        information is gathered. Minimums may apply.
      </Paragraph>
    </Box>
  );
};

export const EquityUnlockEstimation = (): React.ReactElement => {
  const [downloading, setDownloading] = useState(false);
  const { formState, goNext, multiStep } = useStepUtils(
    'equity_unlock_estimation'
  );

  const curated_high_end_range =
    formState.equity_unlock_result?.high_end_range !== undefined
      ? formState.equity_unlock_result?.high_end_range < 0
        ? 0
        : formState.equity_unlock_result?.high_end_range
      : '';

  const curated_low_end_range =
    formState.equity_unlock_result?.low_end_range !== undefined
      ? formState.equity_unlock_result?.low_end_range < 0
        ? 0
        : formState.equity_unlock_result?.low_end_range
      : '';

  const highEndRange = formatCurrency({
    value: curated_high_end_range,
  });

  const lowEndRange = formatCurrency({
    value: curated_low_end_range,
  });
  const range =
    Number(lowEndRange) === 0 && Number(highEndRange) === 0
      ? '$0'
      : `${lowEndRange} - ${highEndRange}`;

  const downloadImage = async () => {
    setDownloading(true);

    const elementId = 'equity-unlock-downloadable';
    const element = document.getElementById(elementId);

    html2canvas(element, {
      onclone: function (clonedDoc) {
        clonedDoc.getElementById(elementId).style.display = 'block';
      },
    }).then((canvas) => {
      const link = canvas.toDataURL('image/png', 1.0);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = link;
      a.download = 'estimated_equity_unlock_range.png';
      a.click();

      setDownloading(false);
    });
  };

  const EquityUnlockResultComponent = () => {
    const { snapshot } = useStepUtils();

    const getMortgageSrc = () => {
      if (snapshot.pre_lead?.id) {
        return leadSource[snapshot.lead_source];
      } else {
        return !formState.mortgage_balance
          ? 'our services'
          : leadSource[formState.flow];
      }
    };

    return (
      <EquityUnlockResult
        highEndRange={highEndRange}
        lowEndRange={lowEndRange}
        assumptions={[
          {
            label: 'Departing Residence',
            value: (
              <Box
                flexDirection="column"
                alignItems="flex-end"
                maxWidth="250px"
                gap="8px"
              >
                <Box>{formState.property_address}</Box>
                <Box>
                  {formState.property_city}, {formState.property_state}{' '}
                  {formState.property_postal_code}
                </Box>
              </Box>
            ),
          },
          {
            label: 'Home Value',
            value: formatCurrency({
              value: formState.estimated_home_value || '',
            }),
          },
          {
            label: 'Mortgage Balance',
            value: formatCurrency({
              value:
                formState.mortgage_balance ||
                formState.equity_unlock_result?.estimated_loan_payoff ||
                '',
            }),
            description: `Value provided by ${getMortgageSrc()}.`,
          },
        ]}
      />
    );
  };

  return (
    <>
      <Step
        title="Equity Unlock Amount"
        description={
          <>
            We estimate your client can unlock between <strong>{range}</strong>.
            The final Equity Unlock Amount will be defined after our team
            reviews the application.
          </>
        }
        mb={{ xs: '60px', md: '0px' }}
        maxWidth="587px"
        banner={{
          text: `Your progress is saved. Continue your submission now, or resume later using the link we emailed you at ${formState.loan_officer_email}.`,
        }}
      >
        <EquityUnlockResultComponent />
        <Button
          height="48px"
          onClick={downloadImage}
          fontSize="16px"
          padding="0 24px !important"
          iconRight={<Icon type="downloadLine" />}
          color="tertiary"
          variant="outlined"
          whiteSpace="nowrap"
          isLoading={downloading}
          disabled={downloading}
          width="100%"
          display={{ xs: 'none', md: 'inherit' }}
        >
          Download Range
        </Button>
        <Box
          display="none"
          id="equity-unlock-downloadable"
          bgcolor={theme.colors.blue100}
          p="96px 60px"
          minWidth="720px"
        >
          <EquityUnlockResultComponent />
        </Box>
      </Step>
      <FloatingFAQButton />
      <Footer
        key="step-footer"
        backButton
        ml={{ xs: '0', md: '380px', lg: '406px' }}
      >
        <Button
          height="48px"
          onClick={downloadImage}
          fontSize="16px"
          padding="0 24px !important"
          iconRight={<Icon type="downloadLine" />}
          color="tertiary"
          variant="outlined"
          whiteSpace="nowrap"
          isLoading={downloading}
          disabled={downloading}
          width="100%"
          display={{ xs: 'inherit', md: 'none' }}
        >
          Download Range
        </Button>
        <Button
          height="48px"
          onClick={() => goNext()}
          fontSize="16px"
          padding="0 24px !important"
          width={{ xs: '100%', md: 'auto' }}
          data-test="next-button"
        >
          Next
        </Button>
      </Footer>
    </>
  );
};
