import { useFormContext } from 'react-hook-form';

import {
  Alert,
  Box,
  Button,
  Icon,
  Inputs,
  Paragraph,
  SlideDown,
} from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { schema } from '../../../metadata/forms';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';
import { SquaredRadio } from '../../squared-radio';

export const ClientInformation = () => {
  const { onUpdateSnapshot } = useLeadSubmissionContext();
  const { watch, setValue } = useFormContext();

  const { has_additional_client, under_contract } = watch();

  const getEnableNext = () => {
    const requiredFields: any = has_additional_client
      ? {
          primary_client_name: true,
          primary_client_email: true,
          primary_client_phone: true,
          additional_client_name: true,
          under_contract: true,
        }
      : {
          primary_client_name: true,
          primary_client_email: true,
          primary_client_phone: true,
          under_contract: true,
        };

    if (under_contract === 'yes') {
      requiredFields.new_property_close_of_escrow_date = true;
    } else {
      delete requiredFields.new_property_close_of_escrow_date;
    }

    const result = schema.required(requiredFields as any).safeParse(
      Object.keys(requiredFields).reduce((obj, key) => {
        const value = watch(key);
        const isEscrowDate = key === 'new_property_close_of_escrow_date';
        const isInvalidDate = value === 'Invalid Date';

        if (isEscrowDate && isInvalidDate) {
          return { ...obj, [key]: undefined };
        }
        return { ...obj, [key]: value };
      }, {})
    );
    return result.success;
  };

  return (
    <>
      <Content>
        <Container
          title="Client Information"
          subtitle="Please include all title holders on the departing residence. "
        >
          <Box flexDirection="column" mb={{ xs: '24px', md: '40px' }}>
            <Box
              mb="32px"
              pb="32px"
              flexDirection="column"
              gap="32px"
              borderBottom="1px solid #F5F6F9"
            >
              <Box flexDirection="column" gap="32px">
                <Paragraph variant="heading-5">Primary client</Paragraph>
                <Inputs.Text
                  name="primary_client_name"
                  label="Client’s Full Name"
                  placeholder="Enter client’s full name"
                  mode="onBlur"
                />
                <Inputs.Email
                  name="primary_client_email"
                  label="Email Address"
                  placeholder="Enter client’s email address"
                  hint="We'll reach out to you before contacting the client. We never send spam."
                  mode="onBlur"
                />
                <Inputs.Phone
                  name="primary_client_phone"
                  label="Phone Number"
                  placeholder="Enter client’s phone number"
                  hint="We'll reach out to you before contacting the client. We never send spam."
                  mode="onBlur"
                />
                {!has_additional_client && (
                  <Button
                    alignSelf="flex-end"
                    color="tertiary"
                    onClick={() => setValue('has_additional_client', true)}
                    iconLeft={<Icon type="addUser" fill="#1192E5" />}
                  >
                    Add client
                  </Button>
                )}
              </Box>

              {has_additional_client && (
                <SlideDown flexDirection="column" gap="32px">
                  <Paragraph variant="heading-5">Additional client</Paragraph>
                  <Inputs.Text
                    name="additional_client_name"
                    label="Client’s Full Name (2)"
                    placeholder="Enter client’s full name"
                    mode="onBlur"
                  />
                  <Inputs.Email
                    name="additional_client_email"
                    label="Email Address (2)"
                    placeholder="Enter client’s email address"
                    mode="onBlur"
                    optional
                  />
                  <Inputs.Phone
                    name="additional_client_phone"
                    label="Phone Number (2)"
                    placeholder="Enter client’s phone number"
                    mode="onBlur"
                    optional
                  />
                  <Button
                    alignSelf="flex-end"
                    color="tertiary"
                    onClick={() => setValue('has_additional_client', false)}
                    iconLeft={<Icon type="trash" fill="#1192E5" />}
                  >
                    Remove client
                  </Button>
                  <Alert>
                    If the property has more than two title holders please let
                    us know in the notes section at the end of the application
                  </Alert>
                </SlideDown>
              )}
            </Box>

            <Box flexDirection="column">
              <Paragraph variant="heading-4" mb="32px">
                Contracts
              </Paragraph>

              <SquaredRadio
                name="under_contract"
                label="Is the client under contract for a new property?"
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
              />

              {under_contract === 'yes' && (
                <SlideDown mt="32px">
                  <Inputs.Date
                    label="What is the close of escrow date?"
                    name="new_property_close_of_escrow_date"
                  />
                </SlideDown>
              )}
            </Box>
          </Box>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={onUpdateSnapshot} disabled={!getEnableNext()} />
      </Footer>
    </>
  );
};
