import {
  SUBMIT_LENDER_BBYS_ALL_CASH_LEAD,
  SUBMIT_LENDER_BBYS_LEAD,
  SUBMIT_LENDER_BBYS_PRE_LEAD,
} from '@hl-portals/constants';

export function getSubmitUrl(current_flow: string, finance_type: string) {
  let url = '';

  if (current_flow === 'agent') {
    url = SUBMIT_LENDER_BBYS_PRE_LEAD;

    if (finance_type === 'cash') {
      url = SUBMIT_LENDER_BBYS_ALL_CASH_LEAD;
    }
  } else {
    url = SUBMIT_LENDER_BBYS_LEAD;
  }

  return url;
}
