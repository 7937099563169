import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Inputs } from '@hl-portals/ui';

import { normalizeCurrency } from '@hl-portals/helpers';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { MIN_HOME_VALUE } from '../../../metadata/constants';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const HomeValue = () => {
  const [showHint, setShowHint] = useState(false);

  const { watch } = useFormContext();
  const { onFetchPropertyUUID, isLoading } = useLeadSubmissionContext();

  const homeValueStr = watch('estimated_home_value');
  const homeValueNum = homeValueStr
    ? normalizeCurrency(homeValueStr)
    : undefined;

  return (
    <>
      <Content>
        <Container
          title="What is the home value?"
          subtitle="Assume the property is in as-is condition."
        >
          <Box flex="1" flexDirection="column">
            <Inputs.Currency
              name="estimated_home_value"
              hint={
                showHint
                  ? 'If the final sale price is under $375,000, the program fee will be $9,000.'
                  : undefined
              }
              hintStyle="warning"
              disabled={isLoading}
              keepHint={showHint}
              onBlur={() => {
                setShowHint(homeValueNum && homeValueNum < MIN_HOME_VALUE);
              }}
              containerProps={{
                maxWidth: { xs: '100%', md: '400px' },
              }}
            />
          </Box>

          <Alert title="Why we ask this" mb={{ xs: '24px', md: '40px' }}>
            We use this as a baseline to calculate the equity unlock loan
            amount.
          </Alert>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={onFetchPropertyUUID} disabled={!homeValueStr} />
      </Footer>
    </>
  );
};
