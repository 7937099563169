import { useFormContext } from 'react-hook-form';

import { Inputs, Paragraph, SimpleLink } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const ProgramEligibility = () => {
  const { onCheckPropertyEligibility } = useLeadSubmissionContext();
  const { watch } = useFormContext();

  const {
    credit_score,
    no_va_fha_requirement,
    no_age_restricted_community,
    primary_residence,
  } = watch();

  const enableNext =
    credit_score &&
    no_va_fha_requirement &&
    no_age_restricted_community &&
    primary_residence;

  return (
    <>
      <Content>
        <Container
          title="Program Eligibility Criteria"
          subtitle="Check each box to proceed."
        >
          <Inputs.Checkbox
            name="credit_score"
            p={{ xs: '16px 0', md: '16px 12px' }}
          >
            <Paragraph variant="text" textAlign="left">
              The client's credit score is 620 or above
            </Paragraph>
          </Inputs.Checkbox>

          <Inputs.Checkbox
            name="no_va_fha_requirement"
            p={{ xs: '16px 0', md: '16px 12px' }}
          >
            <Paragraph variant="text" textAlign="left">
              The client doesn't need a VA, FHA or other government loan for
              their new home purchase
            </Paragraph>
          </Inputs.Checkbox>

          <Inputs.Checkbox
            name="no_age_restricted_community"
            p={{ xs: '16px 0', md: '16px 12px' }}
          >
            <Paragraph variant="text" textAlign="left">
              The property is not located in an age-restricted community
              (Example: 50+ living community)
            </Paragraph>
          </Inputs.Checkbox>

          <Inputs.Checkbox
            name="primary_residence"
            p={{ xs: '16px 0', md: '16px 12px' }}
          >
            <Paragraph variant="text" textAlign="left">
              The property is the client's primary residence
            </Paragraph>
          </Inputs.Checkbox>

          <Paragraph variant="text">
            To know more about Buy Before You Sell Eligibility Requirements{' '}
            <SimpleLink
              fontSize="16px"
              target="_blank"
              color="#46B6FF"
              href="https://help.homelight.com/hc/en-us/articles/10316272759959-homelight-buy-before-you-sell-eligibility-requirements"
            >
              Click here.
            </SimpleLink>
          </Paragraph>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton
          onClick={onCheckPropertyEligibility}
          disabled={!enableNext}
        />
      </Footer>
    </>
  );
};
