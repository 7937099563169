import { useFormContext } from 'react-hook-form';

import { Box } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { CardRadio } from '../../card-radio';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const FinancialType = () => {
  const { onUpdateSnapshot } = useLeadSubmissionContext();
  const { watch, setValue } = useFormContext();

  const { finance_type } = watch();

  const onSelectFinance = (type: string) => {
    setValue('finance_type', type);
  };

  return (
    <>
      <Content>
        <Container
          title="Financial Information"
          subtitle="How will your client finance their new property?"
        >
          <Box flexDirection="column" gap="32px">
            <CardRadio
              title="Mortgage"
              subtitle="My client requires a mortgage to finance the new home."
              icon="bank"
              isActive={finance_type === 'mortgage'}
              onClick={() => onSelectFinance('mortgage')}
              showMarker
            />
            <CardRadio
              title="All cash purchase"
              subtitle="My client will pay for the new home with cash."
              icon="cashOffer"
              isActive={finance_type === 'cash'}
              onClick={() => onSelectFinance('cash')}
              showMarker
            />
            <CardRadio
              title="Unsure"
              subtitle="My client has not decided how they will finance their next home."
              isActive={finance_type === 'unsure'}
              onClick={() => onSelectFinance('unsure')}
              showMarker
            />
          </Box>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={onUpdateSnapshot} disabled={!finance_type} />
      </Footer>
    </>
  );
};
