import { MultiColorIconKey } from '@hl-portals/ui';

// =========================================================================
// Steps

export const STEPS = {
  LEAD_INFORMATION: 'Lead Information',
  DEPARTING_RESIDENCE: 'Departing Residence',
  HOME_VALUE: 'Home Value',
  LIENS_SELECTION: 'Liens Selection',
  LIENS_BALANCES: 'Liens Balances',
  PROGRAM_ELIGIBILITY: 'Program Eligibility',
  EQUITY_UNLOCK_AMOUNT: 'Equity Unlock Amount',
  CLIENT_INFORMATION: 'Client Information',
  FINANCIAL_TYPE: 'Financial Type',
  FINANCIAL_INFORMATION: 'Financial Information',
  AGENT_INFORMATION: 'Agent Information',
  LOAN_OFFICER_INFORMATION: 'Loan Officer Information',

  // Phase 2:
  // AGE_RESTRICTED_COMMUNITY: 'Age Restricted Community',
  // PRIMARY_RESIDENCE: 'Primary Residence',
  // CREDIT_SCORE: 'Credit Score',
  // FINANCING: 'Financing',
  // IS_UNDER_CONTRACT_IN_NEW_HOME: 'Is Under Contract In New Home',
  // NEW_HOME_PURCHASE_TIME_PLANNIG: 'New Home Purchase Time Planning',
  // CLIENT_TARGET_EQUITY_UNLOCK_AMOUNT: 'Client Target Equity Unlock Amount',
  // CLOSING_DATE: 'Closing Date',
  // NEW_HOME_PURCHASE: 'New Home Purchase',
  // UPLOAD_NEW_HOME_CONTRACT: 'Upload New Home Contract',
  // CLIENT_INFORMATION: 'Client Information',
  // AGENT_INFORMATION: 'Agent Information',
  // ASSISTANT_INFORMATION: 'Assistant Information',
  REVIEW_AND_SUBMIT: 'Review And Submit',
  SUCCESS: 'Success',
} as const;

type StepObject = typeof STEPS;
type StepKeys = keyof StepObject;
export type Step = StepObject[StepKeys];

// =========================================================================
// Step With Sidebar Image

type StepWithImage = Exclude<
  Step,
  'Equity Loading' | 'Review And Submit' | 'Success'
>;

export const STEP_SIDEBAR_IMAGE: Record<StepWithImage, MultiColorIconKey> = {
  'Lead Information': 'House1',
  'Departing Residence': 'House2',
  'Home Value': 'House2',
  'Program Eligibility': 'RadioSelect',
  'Liens Selection': 'House2',
  'Liens Balances': 'House2',
  'Equity Unlock Amount': 'Calculator',
  'Client Information': 'Users',
  'Financial Type': 'Bank',
  'Financial Information': 'Bank',
  'Agent Information': 'Users',
  'Loan Officer Information': 'Users',
};

// =========================================================================
// Step to hide Header

export const STEPS_TO_HIDE_HEADER: Step[] = ['Success'];

// =========================================================================
// Step to hide Sidebar

export const STEPS_TO_HIDE_SIDEBAR: Step[] = ['Review And Submit', 'Success'];

// =========================================================================
// Lennar Divisions

export const LENNAR_DIVISIONS = [
  'AL - Gulf Coast',
  'AL - North Alabama',
  'AZ - Arizona Sales Center',
  'AZ - Phoenix',
  'AZ - Tucson',
  'CA - Bay Area-Concord',
  'CA - Central Valley',
  'CA - Coastal',
  'CA - Inland Empire',
  'CA - Sacramento',
  'CA - San Diego',
  'CO - Englewood',
  'FL - Jacksonville',
  'FL - Mid-Florida',
  'FL - Orlando',
  'FL - Palm Atlantic',
  'FL - SE FL South',
  'FL - SW Florida',
  'FL - Tampa',
  'GA - Atlanta',
  'ID - Boise',
  'IL - Chicago',
  'IN - Indianapolis',
  'Maryland',
  'MN - Minneapolis',
  'NC - Charlotte',
  'NC - Charlotte Sales Center',
  'NC - Raleigh',
  'NV - Las Vegas',
  'NV - Reno',
  'NY - New Jersey',
  'OR - Portland',
  'PA - Philadelphia Metro',
  'SC - Charleston',
  'TN - Nashville',
  'TX - Austin',
  'TX - Dallas',
  'TX - Houston',
  'TX - San Antonio',
  'Utah',
  'Virginia',
  'WA - Seattle',
  'Other',
];

export const LENNAR_DIVISION_OPTIONS = LENNAR_DIVISIONS.map((option) => ({
  value: option,
  text: option,
}));

// =========================================================================
// Local Storage Key

export const LOCAL_STORAGE_KEY = 'lead-submission-remember-me';

// =========================================================================
// Liens

export type LienType =
  | 'home_owned'
  | 'mortgage'
  | 'heloc'
  | 'second_mortgage'
  | 'reverse_mortgage'
  | 'solar_lease'
  | 'solar_loan'
  | 'other'
  | '';

export type Lien = {
  type: LienType;
  label: string;
  balance: string;
};

export type Liens = Lien[];

export const LIENS: Liens = [
  {
    type: 'home_owned',
    label: 'No, property is owned free & clear',
    balance: '0',
  },
  { type: 'mortgage', label: 'Mortgage', balance: '0' },
  { type: 'heloc', label: 'HELOC', balance: '0' },
  { type: 'second_mortgage', label: 'Second mortgage', balance: '0' },
  { type: 'reverse_mortgage', label: 'Reverse mortgage', balance: '0' },
  { type: 'solar_lease', label: 'Solar lease', balance: '0' },
  { type: 'solar_loan', label: 'Solar loan', balance: '0' },
  { type: 'other', label: 'Other', balance: '0' },
];

export const HOME_OWNED = LIENS[0].type;
export const LIENS_OPTIONS = LIENS.filter((lien) => lien.type !== HOME_OWNED);

// =========================================================================
// Min Home Value

export const MIN_HOME_VALUE = 375_000;
