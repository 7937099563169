import { useFormContext } from 'react-hook-form';

import { Button, ButtonProps, Icon, useMultiStepContext } from '@hl-portals/ui';

import { UCQuestionnaireFormData } from '../../../metadata/forms';

type BtnProps = Omit<ButtonProps, 'children'>;

export const BackButton = (props: BtnProps) => {
  const { goBack } = useMultiStepContext();

  return (
    <Button
      type="button"
      display={{ xs: 'none', md: 'flex' }}
      color="tertiary"
      size="large"
      onClick={goBack}
      {...props}
    >
      <Icon type="angleLeft" />
      Back
    </Button>
  );
};

type NextButtonProps = {
  validateFields?: Array<keyof UCQuestionnaireFormData>;
} & BtnProps;

export const NextButton = (props: NextButtonProps) => {
  const { goNext } = useMultiStepContext();
  const { trigger } = useFormContext();

  const onClick = async () => {
    if (props.validateFields) {
      const isValid = await trigger(props.validateFields);

      if (isValid) {
        goNext();
      }
      return;
    }
    goNext();
  };

  return (
    <Button
      type="button"
      size="large"
      flex={{ xs: '1', md: 'unset' }}
      onClick={onClick}
      {...props}
    >
      Next
    </Button>
  );
};

export const SubmitButton = (props: BtnProps) => (
  <Button type="button" size="large" flex={{ xs: '1', md: 'unset' }} {...props}>
    Submit
  </Button>
);
