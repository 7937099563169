import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { calculatorLoadingJson } from '@hl-portals/constants';

import { useCalculateLenderEquity } from '@hl-portals/hooks';

import { useStepUtils } from '../hooks';
import { MultiStepLoadingAnimation } from '../layout/MultiStepLoadingAnimation';
import Step from '../layout/Step';

const randomIntBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const CalculatingEquityUnlock = (): React.ReactElement => {
  const {
    formContext: { setValue },
    formState,
    goNext,
    goBack,
  } = useStepUtils();

  const [loadingState, setLoadingState] = useState<
    Record<symbol | string, 'loading' | 'pending' | 'done'>
  >({
    eligibility: 'loading',
    valuation: 'pending',
    calculation: 'pending',
  });
  const [canGoNext, setCanGoNext] = useState(false);
  let sourceForm = 'public_lead_submission_page';
  let source = 'web';

  if (formState.flow === 'agentSubmission') {
    sourceForm = 'public_agent_lead_submission_page';
  }

  if (formState.flow === 'externalLeadSubmitter') {
    sourceForm = 'public_external_lead_submitter_page';
  }
  const params = {
    lenderName: formState.loan_officer_name,
    lenderEmail: formState.loan_officer_email,
    lenderPhone: formState.loan_officer_phone,
    lenderCompany: formState.lender_company_name,
    uuid: formState.property_uuid,
    state: formState.property_state,
    value: formState.estimated_home_value,
    mortgage: formState.mortgage_balance,
    targetAmount: formState.target_equity_unlock,
    targetAmountRadio: formState.target_amount_radio,
    snapshotUuid: formState.snapshot_uuid,
    snapshotId: formState.snapshot_id,
    partnerSlug: formState.partner_slug,
    agentName: formState.agent_name,
    agentEmail: formState.agent_email,
    agentPhone: formState.agent_phone,
    salesConsultantName: formState.sales_consultant_name,
    salesConsultantEmail: formState.sales_consultant_email,
    salesConsultantPhone: formState.sales_consultant_phone,
    sourceForm,
    source,
  };

  const { refetch: calculate } = useCalculateLenderEquity(params, {
    onSuccess: (result) => {
      setValue('equity_unlock_result', result, { shouldValidate: false });
    },
    onError: () => {
      toast('There was an error calculating the equity unlock amount', {
        type: 'error',
      });
      goBack();
    },
  });

  const startLoadingTimeouts = () => {
    setLoadingState({
      eligibility: 'loading',
      valuation: 'pending',
      calculation: 'pending',
    });

    setTimeout(() => {
      setLoadingState({
        eligibility: 'done',
        valuation: 'loading',
        calculation: 'pending',
      });

      setTimeout(() => {
        setLoadingState({
          eligibility: 'done',
          valuation: 'done',
          calculation: 'loading',
        });

        setTimeout(() => {
          setCanGoNext(true);
        }, randomIntBetween(500, 1500));
      }, randomIntBetween(500, 1500));
    }, randomIntBetween(500, 1500));
  };

  useEffect(() => {
    if (
      JSON.stringify(formState.equity_request_params) ===
        JSON.stringify(params) &&
      !!formState.equity_unlock_result
    ) {
      goNext();
    } else {
      setCanGoNext(false);
      startLoadingTimeouts();
      calculate();
      setValue('equity_request_params', params);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!canGoNext || !formState.equity_unlock_result) return;

    setLoadingState({
      eligibility: 'done',
      valuation: 'done',
      calculation: 'done',
    });

    setTimeout(() => {
      goNext({ replace: true });
    }, 1000);
  }, [formState.equity_unlock_result, canGoNext]);

  return (
    <Step mt="80px">
      <MultiStepLoadingAnimation
        animationJson={calculatorLoadingJson}
        steps={[
          {
            label: 'Verifying property eligibility',
            status: loadingState.eligibility,
          },
          {
            label: 'Consulting valuation models',
            status: loadingState.valuation,
          },
          {
            label: 'Estimating Equity Unlock Amount',
            status: loadingState.calculation,
          },
        ]}
      />
    </Step>
  );
};
