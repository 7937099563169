import { useFormContext } from 'react-hook-form';

import { Alert, Inputs, SlideDown } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { schema } from '../../../metadata/forms';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';
import { SquaredRadio } from '../../squared-radio';

export const AgentInformation = () => {
  const { onUpdateSnapshot } = useLeadSubmissionContext();
  const { watch } = useFormContext();

  const { dr_agent_present, partner_slug } = watch();

  const { name, email, phone } =
    partner_slug === 'lennar'
      ? {
          name: 'sales_consultant_name',
          email: 'sales_consultant_email',
          phone: 'sales_consultant_phone',
        }
      : {
          name: 'agent_name',
          email: 'agent_email',
          phone: 'agent_phone',
        };

  const getEnableNext = () => {
    if (dr_agent_present === 'yes') {
      let requiredFields = { dr_agent_present: true } as any;

      if (partner_slug === 'lennar') {
        requiredFields = {
          sales_consultant_name: true,
          sales_consultant_email: true,
          sales_consultant_phone: true,
        };
      } else {
        requiredFields = {
          agent_name: true,
          agent_email: true,
          agent_phone: true,
        };
      }

      const result = schema.required(requiredFields as any).safeParse(
        Object.keys(requiredFields).reduce((obj, key) => {
          return { ...obj, [key]: watch(key) };
        }, {})
      );
      return result.success;
    }
    if (dr_agent_present === 'no') return true;
    return false;
  };

  return (
    <>
      <Content>
        <Container title="Agent Information">
          <SquaredRadio
            flex="unset"
            mb="32px"
            name="dr_agent_present"
            label="Is the client working with an agent for the sale of the departing residence?"
            options={[
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' },
            ]}
          />

          {dr_agent_present === 'yes' && (
            <SlideDown flexDirection="column" gap="32px">
              <Alert>
                We'll use this info to send task reminders and notifications.
              </Alert>

              <Inputs.Text
                name={name}
                label="Agent’s Full Name"
                placeholder="Enter Agent’s Full Name"
                mode="onBlur"
              />
              <Inputs.Email
                name={email}
                label="Agent’s Email Address"
                placeholder="agent@example.com"
                mode="onBlur"
              />
              <Inputs.Phone
                name={phone}
                label="Agent’s Phone Number"
                placeholder="(555) 555 - 5555"
                mode="onBlur"
                optional
              />
            </SlideDown>
          )}

          {dr_agent_present === 'no' && (
            <Alert>
              Please note that the program requires a licensed real estate agent
              for the sale of the departing residence. You can continue with the
              submission and we’ll ask for this information later.
            </Alert>
          )}
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={onUpdateSnapshot} disabled={!getEnableNext()} />
      </Footer>
    </>
  );
};
